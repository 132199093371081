<template>
    <div>

            <el-cascader
                ref="cascaderRef"
                v-model="getdefaultclassid"
                :props="data.cascaderProps"
                :options="data.list"
                :collapse-tags="typeof data.collapseTags === 'undefined' ? true : data.collapseTags"
                :placeholder="(data && data.placeholder) ? data.placeholder : '请选择'"
                :clearable="(data.value && data.value.length > 1) ? (typeof data.clearable !== 'undefined' ? data.clearable : true) : false"
                filterable
                

                @change="changeSel(data)"
                @expand-change="selectclass"
                :class="{
                    collapseCascader: typeof data.collapseTags === 'undefined',
                    'el-cascader_single': data.value && data.value.length == 1
                }"
                popper-class="expand-filter-cascader-popover"
            ></el-cascader>

    </div>
</template>

<script>
import object from 'element-resize-detector/src/detection-strategy/object';

    export default {
        name: 'Select',
        data(){
            return{
                getdefaultclassid:this.defaultclassid
            }
        },
        props: {
            defaultclassid:{
               type:String
            },
            data: {
                type: Object
            }
        },
        mounted(){
          console.log("获取班级id,data是什么数据",this.defaultclassid,this.data)
        },
        methods: {
            clearnode(){
                // console.log(this.$refs.cascaderRef,'this.$refs.cascaderRef')
                // this.$refs.cascaderRef.clearCheckedNodes()
                this.getdefaultclassid = [];
            },
            changeSel (data) {
                this.$emit('changeCascader', {
                    data: data,
                    ref: this.$refs.cascaderRef
                })
                this.$eventDispatch('changeCascader', {
                    data: data,
                    ref: this.$refs.cascaderRef
                })
                // this.$eventBus.$emit('changeSel',data)
            },
            selectclass(val){
                console.log("1431",val)

            }
        }
    }
</script>
<style lang="scss" scoped>
::v-deep .collapseCascader {
    width: 100%;

    .el-cascader__tags {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        /* margin-right: 50px; */

        .el-tag {
            max-width: calc(100% - 50px);

            + .el-cascader__search-input {
                margin-left: 6px;
                min-width: 7px;
            }
        }
    }
}

::v-deep .el-cascader_single {
    background-color: red;
    .el-cascader__tags {
        .el-tag {
            max-width: 100%;
            width: 100%;
        }

        .el-cascader__search-input {
            display: none;
        }
    }
}
</style>

<style>
.expand-filter-cascader-popover .el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover {
    background-color: #f0f0f0 !important;
}
</style>
